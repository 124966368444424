@import "./globalColor";

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;

}

body::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $appHeaderBG;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: $textColorDark;
}

.App-link {
  color: $appLink;
}

.subTitle {
  color: $subTitle;
}
